import {
  TrendingUp as TrendingUpIcon,
  FileText as FileTextIcon,
  Airplay as AirplayIcon,
  PieChart as PieChartIcon,
  BookOpen as BookOpenIcon,
  Users as UsersIcon,
  Sliders as SlidersIcon,
  UserCheck as UserCheckIcon,
  Smile as SmileIcon,
  UserPlus as UserPlusIcon
} from 'react-feather';
import { FaFileInvoice } from 'react-icons/fa';

const superAdminNav = [
  {
    subheader: 'Overview',
    items: [
      {
        title: 'Dashboard',
        icon: PieChartIcon,
        href: '/app/reports/dashboard'
      }
    ]
  },
  {
    subheader: 'Tutoring',
    items: [
      {
        title: 'Customers',
        icon: SmileIcon,
        href: '/app/tutoring/customers',
        items: [
          {
            title: 'List Customers',
            href: '/app/tutoring/customers'
          },
          {
            title: 'List Dependents',
            href: '/app/tutoring/dependents'
          },
          {
            title: 'List Customer Pods',
            href: '/app/tutoring/customer-pods'
          }
        ]
      },
      {
        title: 'Tutors',
        icon: SmileIcon,
        href: '/app/tutoring/tutors',
        items: [
          {
            title: 'List Tutors',
            href: '/app/tutoring/tutors'
          },
          {
            title: 'Calendar',
            href: '/app/tutoring/calendar'
          },
          {
            title: 'Hiring',
            href: '/app/tutoring/onboarding'
          },
          {
            title: 'Rejected',
            href: '/app/tutoring/rejected-tutors'
          }
        ]
      },
      {
        title: 'Lessons',
        icon: BookOpenIcon,
        href: '/app/content',
        items: [
          {
            title: 'Lesson Requests',
            href: '/app/tutoring/admin-lesson-requests'
          },
          {
            title: 'Confirmed Lessons',
            href: '/app/tutoring/admin-confirmed-lessons'
          },
          {
            title: 'Booked Lessons',
            href: '/app/tutoring/admin-booked-lessons'
          },
          {
            title: 'Learning Pods',
            href: '/app/tutoring/group-lessons'
          },
          {
            title: 'Virtual Immersions',
            href: '/app/tutoring/virtual-immersion'
          },
          {
            title: 'Tutor Feedback',
            href: '/app/tutoring/feedbacks'
          }
        ]
      },
      {
        title: 'Referral Scheme',
        icon: UserPlusIcon,
        href: '/app/content',
        items: [
          {
            title: 'Teacher Referrers',
            href: '/app/tutoring/teacher-referrers'
          },
          {
            title: 'Customer Referrers',
            href: '/app/tutoring/customer-referrers'
          }
        ]
      },
      {
        title: 'Curriculums',
        icon: BookOpenIcon,
        href: '/app/tutoring',
        items: [
          {
            title: 'Language Curriculum',
            href: '/app/tutoring/curriculums'
          },
          {
            title: 'Curriculum',
            href: '/app/tutoring/regions'
          }
        ]
      },
      {
        title: 'Invoice',
        icon: FaFileInvoice,
        href: '/app/tutoring',
        items: [
          {
            title: 'PrePayment Invoice',
            href: '/app/tutoring/pre-payment-invoice'
          },
          {
            title: 'Ongoing Invoice',
            href: '/app/tutoring/ongoing-customer-invoice'
          },
          {
            title: 'Promos',
            href: '/app/tutoring/invoice/promos'
          },
          {
            title: 'Create Invoice',
            href: '/app/tutoring/invoice/new-pre-payment-invoice'
          }
        ]
      },
      {
        title: 'Reports',
        icon: TrendingUpIcon,
        href: '/app/content',
        items: [
          {
            title: 'Booked Lessons',
            href: '/app/tutoring/reports/student-lessons'
          },
          {
            title: 'Rescheduled Lessons',
            href: '/app/tutoring/reports/rescheduled-lessons'
          },
          {
            title: 'Virtual Immersions',
            href: '/app/tutoring/reports/virtual-immersions'
          },
          {
            title: 'Student Retention',
            href: '/app/tutoring/reports/student-retention'
          },
          {
            title: 'Lesson Ratings',
            href: '/app/tutoring/reports/student-rating'
          },
          {
            title: 'Direct to Pay Leads',
            href: '/app/tutoring/reports/customer-leads'
          }
        ]
      }
      // {
      //   title: 'Group Lessons',
      //   icon: BookOpenIcon,
      //   href: '/app/content',
      //   items: [
      //     {
      //       title: 'View Lessons',
      //       href: '/app/tutoring/group-lessons'
      //     },
      //     {
      //       title: 'Create',
      //       href: '/app/tutoring/group-lessons/create'
      //     }
      //   ]
      // }
    ]
  },
  {
    subheader: 'Language App',
    items: [
      {
        title: 'Words List',
        icon: BookOpenIcon,
        href: '/app/words-list',
        items: [
          {
            title: 'Manage Words',
            href: '/app/langauge-app/words-list'
          },
          {
            title: 'Add Word',
            href: '/app/langauge-app/create-word'
          }
        ]
      },
      {
        title: 'Sentence Construction',
        icon: SlidersIcon,
        href: '/app/sentence-construction',
        items: [
          {
            title: 'Manage Sessions',
            href: '/app/langauge-app/sentence-construction'
          },
          {
            title: 'Add Sessions',
            href: '/app/langauge-app/create-sentence-construction-session'
          }
        ]
      }
    ]
  }
  // {
  //   subheader: 'Learning',
  //   items: [
  //     {
  //       title: 'App Content',
  //       icon: BookOpenIcon,
  //       href: '/app/content',
  //       items: [
  //         {
  //           title: 'Curriculum',
  //           href: '/app/content/exams'
  //         },
  //         {
  //           title: 'Lessons Content Status',
  //           href: '/app/content/lesson-status'
  //         },
  //         {
  //           title: 'Content Creators',
  //           href: '/app/content/content-creators'
  //         }
  //       ]
  //     }
  //   ]
  // }
];

export default superAdminNav;
