/* eslint-disable react/no-array-index-key */
import React, { lazy, Suspense, Fragment } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import DocsLayout from 'src/layouts/DocsLayout';
import MainLayout from 'src/layouts/MainLayout';
import HomeView from 'src/views/pages/HomeView';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import ContentCreatorGuard from 'src/components/ContentCreatorGuard';

const routesConfig = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/app" />
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/pages/Error404View'))
  },
  {
    exact: true,
    path: '/activate-account/:user_id',
    component: lazy(() => import('src/views/pages/activateAccount'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    path: '/register-unprotected',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/tutoring/admin-lesson-requests" />
      },
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/pages/AccountView'))
      },
      {
        exact: true,
        path: '/app/reports/dashboard-standard',
        component: lazy(() => import('src/views/reports/DashboardView'))
      },
      {
        exact: true,
        path: '/app/reports/dashboard',
        guard: ContentCreatorGuard,
        component: lazy(() => import('src/views/reports/Dashboard'))
      },
      {
        exact: true,
        path: '/app/reports/tutor-ratings',
        component: lazy(() => import('src/views/reports/TutorRatings'))
      },
      {
        exact: true,
        path: '/app/reports/student-no-show',
        component: lazy(() => import('src/views/reports/StudentNoShow'))
      },
      {
        exact: true,
        path: '/app/reports/unconfirmed-lessons',
        component: lazy(() => import('src/views/reports/UnconfirmedLessons'))
      },
      {
        exact: true,
        path: '/app/reports/team-activities',
        component: lazy(() => import('src/views/reports/TeamActivitiesView'))
      },
      {
        exact: true,
        path: '/app/reports/dashboard-alternative',
        component: lazy(() =>
          import('src/views/reports/DashboardAlternativeView')
        )
      },
      {
        exact: true,
        path: '/app/reports',
        guard: ContentCreatorGuard,
        component: () => <Redirect to="/app/reports/dashboard" />
      },
      {
        exact: true,
        path: '/app/management/create/user',
        guard: ContentCreatorGuard,
        component: lazy(() =>
          import('src/views/management/AdminUserCreateView')
        )
      },
      {
        exact: true,
        path: '/app/management/users',
        guard: ContentCreatorGuard,
        component: lazy(() => import('src/views/management/AdminUserListView'))
      },
      {
        exact: true,
        path: '/app/management/customers',
        guard: ContentCreatorGuard,
        component: lazy(() => import('src/views/management/CustomerListView'))
      },
      {
        exact: true,
        guard: ContentCreatorGuard,
        path: '/app/tutoring/admin-lesson-requests',
        component: lazy(() =>
          import(
            'src/views/tutoring/lessons/lesson-requests/LessonRequestListView'
          )
        )
      },
      {
        exact: true,
        guard: ContentCreatorGuard,
        path: '/app/tutoring/admin-booked-lessons',
        component: lazy(() =>
          import(
            'src/views/tutoring/lessons/confirmed-lessons/BookedLessonsListView'
          )
        )
      },
      {
        exact: true,
        guard: ContentCreatorGuard,
        path: '/app/tutoring/admin-confirmed-lessons',
        component: lazy(() =>
          import(
            'src/views/tutoring/lessons/confirmed-lessons/ConfirmedLessonsAdminView'
          )
        )
      },
      {
        exact: true,
        guard: ContentCreatorGuard,
        path: '/app/tutoring/lesson-activity-log/:lessonId',
        component: lazy(() =>
          import(
            'src/views/tutoring/lessons/confirmed-lessons/LessonActivityLogListView'
          )
        )
      },

      {
        exact: true,
        guard: ContentCreatorGuard,
        path: '/app/tutoring/lesson-rating/:lessonId',
        component: lazy(() =>
          import(
            'src/views/tutoring/lessons/confirmed-lessons/LessonRatingView'
          )
        )
      },
      {
        exact: true,
        guard: ContentCreatorGuard,
        path: '/app/tutoring/reports/student-lessons',
        component: lazy(() =>
          import('src/views/tutoring/reports/lessons/ConfirmedLessonsListView')
        )
      },
      {
        exact: true,
        guard: ContentCreatorGuard,
        path: '/app/tutoring/reports/customer-leads',
        component: lazy(() =>
          import('src/views/tutoring/reports/CustomerLeadListView')
        )
      },
      {
        exact: true,
        guard: ContentCreatorGuard,
        path: '/app/tutoring/virtual-immersion',
        component: lazy(() =>
          import(
            'src/views/tutoring/virtual-immersions/VirtualImmersionListView'
          )
        )
      },
      {
        exact: true,
        guard: ContentCreatorGuard,
        path: '/app/tutoring/reports/rescheduled-lessons',
        component: lazy(() =>
          import(
            'src/views/tutoring/reports/lessons/RescheduledLessonsListView'
          )
        )
      },
      {
        exact: true,
        guard: ContentCreatorGuard,
        path: '/app/tutoring/reports/student-retention',
        component: lazy(() =>
          import(
            'src/views/tutoring/reports/lessons/StudentWeeklyLessonsListView'
          )
        )
      },
      {
        exact: true,
        guard: ContentCreatorGuard,
        path: '/app/tutoring/reports/student-rating',
        component: lazy(() =>
          import('src/views/tutoring/reports/lessons/StudentRatingsListView')
        )
      },
      {
        exact: true,
        guard: ContentCreatorGuard,
        path: '/app/tutoring/reports/virtual-immersions',
        component: lazy(() =>
          import('src/views/tutoring/reports/VirtualImmersion')
        )
      },
      {
        exact: true,
        guard: ContentCreatorGuard,
        path: '/app/tutoring/customers',
        component: lazy(() =>
          import('src/views/tutoring/customers/CustomerListView')
        )
      },
      {
        exact: true,
        guard: ContentCreatorGuard,
        path: '/app/tutoring/customer-pods',
        component: lazy(() =>
          import('src/views/tutoring/customers/CustomerPodsListView')
        )
      },
      {
        exact: true,
        guard: ContentCreatorGuard,
        path: '/app/tutoring/customer-pods/:podId',
        component: lazy(() =>
          import(
            'src/views/tutoring/customers/CustomerPodsListView/CustomerPodView'
          )
        )
      },
      {
        exact: true,
        guard: ContentCreatorGuard,
        path: '/app/tutoring/dependents',
        component: lazy(() =>
          import('src/views/tutoring/customers/DependentListView')
        )
      },
      {
        exact: true,
        guard: ContentCreatorGuard,
        path: '/app/tutoring/tutors',
        component: lazy(() => import('src/views/tutoring/tutors/TutorListView'))
      },
      {
        exact: true,
        guard: ContentCreatorGuard,
        path: '/app/tutoring/calendar',
        component: lazy(() =>
          import('src/views/tutoring/calendar/CalendarView')
        )
      },
      {
        exact: true,
        guard: ContentCreatorGuard,
        path: '/app/tutoring/onboarding',
        component: lazy(() =>
          import('src/views/tutoring/hiring/HiringListView')
        )
      },
      {
        exact: true,
        guard: ContentCreatorGuard,
        path: '/app/tutoring/rejected-tutors',
        component: lazy(() =>
          import('src/views/tutoring/hiring/RejectedListView')
        )
      },
      {
        exact: true,
        guard: ContentCreatorGuard,
        path: '/app/tutoring/onboarding/:customerId',
        component: lazy(() => import('src/views/tutoring/hiring/TutorInfoView'))
      },
      {
        exact: true,
        guard: ContentCreatorGuard,
        path: '/app/tutoring/tutors/:customerId',
        component: lazy(() =>
          import('src/views/tutoring/tutors/TutorDetailsView')
        )
      },
      {
        exact: true,
        guard: ContentCreatorGuard,
        path: '/app/tutoring/customers/:customerId',
        component: lazy(() =>
          import('src/views/tutoring/customers/CustomerDetailsView')
        )
      },
      {
        exact: true,
        guard: ContentCreatorGuard,
        path: '/app/tutoring/admin-lesson-requests/view/:requestId',
        component: lazy(() =>
          import('src/views/tutoring/lessons/lesson-requests/LessonRequestView')
        )
      },
      {
        exact: true,
        guard: ContentCreatorGuard,
        path: '/app/tutoring/group-lessons/create',
        component: lazy(() =>
          import('src/views/tutoring/group-lessons/GroupLessonCreateView')
        )
      },
      {
        exact: true,
        guard: ContentCreatorGuard,
        path: '/app/tutoring/feedbacks',
        component: lazy(() => import('src/views/tutoring/Feedbacks'))
      },
      {
        exact: true,
        guard: ContentCreatorGuard,
        path: '/app/tutoring/feedbacks/:id',
        component: lazy(() => import('src/views/tutoring/Feedbacks/Card'))
      },
      {
        exact: true,
        guard: ContentCreatorGuard,
        path: '/app/tutoring/group-lessons',
        component: lazy(() =>
          import('src/views/tutoring/group-lessons/GroupLessonListView')
        )
      },
      {
        exact: true,
        guard: ContentCreatorGuard,
        path: '/app/tutoring/group-lesson/:groupLessonId/instance/create',
        component: lazy(() =>
          import(
            'src/views/tutoring/group-lessons/Instances/GroupLessonInstanceCreate'
          )
        )
      },
      {
        exact: true,
        guard: ContentCreatorGuard,
        path: '/app/tutoring/group-lessons/edit/:groupLessonId',
        component: lazy(() =>
          import('src/views/tutoring/group-lessons/GroupLessonEditView')
        )
      },
      {
        exact: true,
        guard: ContentCreatorGuard,
        path: '/app/tutoring/group-lessons/instances/:groupLessonId',
        component: lazy(() =>
          import(
            'src/views/tutoring/group-lessons/Instances/LessonInstanceListView'
          )
        )
      },
      {
        exact: true,
        guard: ContentCreatorGuard,
        path: '/app/tutoring/virtual-immersions/instances/:groupLessonId',
        component: lazy(() =>
          import(
            'src/views/tutoring/virtual-immersions/Instances/LessonInstanceListView'
          )
        )
      },
      {
        exact: true,
        path: '/app/langauge-app/words-list',
        component: lazy(() =>
          import('src/views/language-app/WordsList/WordsListView')
        )
      },
      {
        exact: true,
        path: '/app/langauge-app/sentence-construction',
        component: lazy(() =>
          import('src/views/language-app/SentenceConstruction/SessionsListView')
        )
      },
      {
        exact: true,
        path: '/app/langauge-app/create-sentence-construction-session',
        component: lazy(() =>
          import(
            'src/views/language-app/SentenceConstruction/CreateSessionsView/index'
          )
        )
      },
      {
        exact: true,
        path: '/app/langauge-app/edit-session/:sessionId',
        component: lazy(() =>
          import('src/views/language-app/SentenceConstruction/EditSessionsView')
        )
      },
      {
        exact: true,
        path: '/app/langauge-app/create-word',
        component: lazy(() =>
          import('src/views/language-app/WordsList/CreateWordView')
        )
      },
      {
        exact: true,
        path: '/app/langauge-app/edit-word/:wordId',
        component: lazy(() =>
          import('src/views/language-app/WordsList/SingleWordEditView')
        )
      },
      {
        exact: true,
        path: '/app/content/exams',
        guard: ContentCreatorGuard,
        component: lazy(() => import('src/views/content/ExamListView'))
      },
      {
        exact: true,
        path: '/app/tutoring/regions',
        guard: ContentCreatorGuard,
        component: lazy(() => import('src/views/content/RegionsView'))
      },
      {
        exact: true,
        path: '/app/tutoring/invoice/promos',
        guard: ContentCreatorGuard,
        component: lazy(() => import('src/views/tutoring/invoice/promos'))
      },
      {
        exact: true,
        path: '/app/tutoring/ongoing-customer-invoice',
        guard: ContentCreatorGuard,
        component: lazy(() => import('src/views/tutoring/invoice/pre'))
      },
      {
        exact: true,
        path: '/app/tutoring/pre-payment-invoice',
        guard: ContentCreatorGuard,
        component: lazy(() => import('src/views/tutoring/invoice/pre'))
      },
      {
        exact: true,
        path: '/app/tutoring/invoice/new-pre-payment-invoice',
        guard: ContentCreatorGuard,
        component: lazy(() =>
          import(
            'src/views/tutoring/invoice/pre/PreInvoiceCreate/PreInvoiceCreate'
          )
        )
      },
      {
        exact: true,
        path: '/app/tutoring/pre-payment-invoice/new',
        guard: ContentCreatorGuard,
        component: lazy(() =>
          import(
            'src/views/tutoring/invoice/pre/PreInvoiceCreateOld/PreInvoiceCreate'
          )
        )
      },
      {
        exact: true,
        path: '/app/tutoring/ongoing-customer-invoice/new',
        guard: ContentCreatorGuard,
        component: lazy(() =>
          import(
            'src/views/tutoring/invoice/pre/PreInvoiceCreateOld/PreInvoiceCreate'
          )
        )
      },
      {
        exact: true,
        path: '/app/tutoring/ongoing-customer-invoice/:invoiceId',
        guard: ContentCreatorGuard,
        component: lazy(() =>
          import(
            'src/views/tutoring/invoice/pre/PreInvoiceCreate/PreInvoiceCreate'
          )
        )
      },
      {
        exact: true,
        path: '/app/tutoring/ongoing-customer-invoice-old/:invoiceId',
        guard: ContentCreatorGuard,
        component: lazy(() =>
          import(
            'src/views/tutoring/invoice/pre/PreInvoiceCreateOld/PreInvoiceCreate'
          )
        )
      },
      {
        exact: true,
        path: '/app/tutoring/pre-payment-invoice/:invoiceId',
        guard: ContentCreatorGuard,
        component: lazy(() =>
          import(
            'src/views/tutoring/invoice/pre/PreInvoiceCreate/PreInvoiceCreate'
          )
        )
      },
      {
        exact: true,
        path: '/app/tutoring/pre-payment-invoice-old/:invoiceId',
        guard: ContentCreatorGuard,
        component: lazy(() =>
          import(
            'src/views/tutoring/invoice/pre/PreInvoiceCreateOld/PreInvoiceCreate'
          )
        )
      },

      {
        exact: true,
        path: '/app/content/exam/:examSlug',
        guard: ContentCreatorGuard,
        component: lazy(() => import('src/views/content/SubjectListView'))
      },
      {
        exact: true,
        path: '/app/content/exam/:examSlug/:subjectId',
        guard: ContentCreatorGuard,
        component: lazy(() => import('src/views/content/TopicListView'))
      },
      {
        exact: true,
        path: '/app/content/exam/:examSlug/:subjectId/:topicId',
        guard: ContentCreatorGuard,
        component: lazy(() => import('src/views/content/SectionListView'))
      },
      {
        exact: true,
        guard: ContentCreatorGuard,
        path: '/app/content/exam/:examSlug/:subjectId/:topicId/:sectionId',
        component: lazy(() => import('src/views/content/LessonListView'))
      },
      {
        exact: true,
        path:
          '/app/content/exam/:examSlug/:subjectId/:topicId/:sectionId/:lessonId',
        component: lazy(() => import('src/views/content/LessonDetailsView'))
      },
      // {
      //   exact: true,
      //   path:
      //     '/app/content/exam/:examSlug/:subjectId/:topicId/:sectionId/:lessonId/create-session',
      //   component: lazy(() => import('src/views/content/SessionCreateView'))
      // },
      {
        exact: true,
        path:
          '/app/content/exam/:examSlug/:subjectId/:topicId/:sectionId/:lessonId/edit-session',
        component: lazy(() => import('src/views/content/SessionEditView'))
      },
      {
        exact: true,
        guard: ContentCreatorGuard,
        path: '/app/content/create/exam',
        component: lazy(() => import('src/views/content/ExamCreateView'))
      },
      {
        exact: true,
        path: '/app/content/edit-exam/:examSlug',
        guard: ContentCreatorGuard,
        component: lazy(() => import('src/views/content/ExamEditView'))
      },
      {
        exact: true,
        path: '/app/content/create/:examSlug/subject',
        guard: ContentCreatorGuard,
        component: lazy(() => import('src/views/content/SubjectCreateView'))
      },
      {
        exact: true,
        guard: ContentCreatorGuard,
        path:
          '/app/content/create/topic/:exam_slug/:subject_id/:exam_name/:subject_name',
        component: lazy(() => import('src/views/content/TopicCreateView'))
      },
      {
        exact: true,
        guard: ContentCreatorGuard,
        path:
          '/app/content/create/section/:exam_slug/:subject_name/:topic_name/:exam_name/:subject_id/:topic_id',
        component: lazy(() => import('src/views/content/SectionCreateView'))
      },
      {
        exact: true,
        guard: ContentCreatorGuard,
        path:
          '/app/content/create/lesson/:exam_slug/:subject_name/:topic_name/:section_name/:exam_name/:subject_id/:topic_id/:section_id',
        component: lazy(() => import('src/views/content/LessonCreateView'))
      },
      {
        exact: true,
        guard: ContentCreatorGuard,
        path:
          '/app/content/edit-subject/:examSlug/:subject_name/:subject_id/:exam_name',
        component: lazy(() => import('src/views/content/SubjectEditView'))
      },
      {
        exact: true,
        guard: ContentCreatorGuard,
        path:
          '/app/content/edit-topic/:exam_slug/:subject_name/:topic_name/:exam_name/:subject_id/:topic_id',
        component: lazy(() => import('src/views/content/TopicEditView'))
      },
      {
        exact: true,
        guard: ContentCreatorGuard,
        path:
          '/app/content/edit-section/:exam_slug/:subject_name/:topic_name/:section_name/:exam_name/:subject_id/:topic_id/:section_id',
        component: lazy(() => import('src/views/content/SectionEditView'))
      },
      {
        exact: true,
        guard: ContentCreatorGuard,
        path:
          '/app/content/edit-lesson/:exam_slug/:subject_name/:topic_name/:section_name/:lesson_name/:exam_name/:subject_id/:topic_id/:section_id/:lesson_id',
        component: lazy(() => import('src/views/content/LessonEditView'))
      },
      {
        exact: true,
        guard: ContentCreatorGuard,
        path: '/app/content/lesson-status',
        component: lazy(() => import('src/views/content/LessonStatusList'))
      },
      {
        exact: true,
        guard: ContentCreatorGuard,
        path: '/app/content/content-creator/create',
        component: lazy(() =>
          import('src/views/content/ContentCreatorCreateView')
        )
      },
      {
        exact: true,
        guard: ContentCreatorGuard,
        path: '/app/content/content-creator/:content_creator_id/edit',
        component: lazy(() =>
          import('src/views/content/ContentCreatorEditView')
        )
      },
      {
        exact: true,
        guard: ContentCreatorGuard,
        path: '/app/content/content-creators',
        component: lazy(() =>
          import(
            'src/views/content/ContentCreatorDetailsView/ContentCreatorListView'
          )
        )
      },
      {
        exact: true,
        path: '/app/content/content-creators/my-lessons/:content_creator_id',
        component: lazy(() => import('src/views/content/ContentCreatorHome'))
      },
      {
        exact: true,
        guard: ContentCreatorGuard,
        path: '/app/content/content-creators/assign-lesson/:user_id',
        component: lazy(() => import('src/views/content/AssignLessonView'))
      },
      {
        exact: true,
        guard: ContentCreatorGuard,
        path: '/app/content/lesson-status/assign-content-creator/:lesson_id',
        component: lazy(() => import('src/views/content/AssignContentCreator'))
      },
      {
        exact: true,
        guard: ContentCreatorGuard,
        path: '/app/management/customers/:customerId',
        component: lazy(() =>
          import('src/views/management/CustomerDetailsView')
        )
      },
      {
        exact: true,
        guard: ContentCreatorGuard,
        path: '/app/tutoring/teacher-referrer/:customerId',
        component: lazy(() =>
          import(
            'src/views/tutoring/referral-scheme/TeacherReferralDetailsView'
          )
        )
      },
      {
        exact: true,
        guard: ContentCreatorGuard,
        path: '/app/tutoring/teacher-referrers',
        component: lazy(() =>
          import('src/views/tutoring/referral-scheme/TeacherReferralListView')
        )
      },
      {
        exact: true,
        guard: ContentCreatorGuard,
        path: '/app/tutoring/customer-referrers',
        component: lazy(() =>
          import('src/views/tutoring/referral-scheme/CustomerReferralListView')
        )
      },
      {
        exact: true,
        guard: ContentCreatorGuard,
        path: '/app/management/admin/:userId',
        component: lazy(() =>
          import('src/views/management/AdminUserDetailsView')
        )
      },
      {
        exact: true,
        guard: ContentCreatorGuard,
        path: '/app/content/content-creator/:userId',
        component: lazy(() =>
          import('src/views/content/ContentCreatorDetailsView')
        )
      },
      {
        exact: true,
        guard: ContentCreatorGuard,
        path: '/app/management/customers/:customerId/edit',
        component: lazy(() => import('src/views/management/CustomerEditView'))
      },
      {
        exact: true,
        path: '/app/management/products',
        component: lazy(() => import('src/views/management/ProductListView'))
      },
      {
        exact: true,
        path: '/app/management/products/create',
        component: lazy(() => import('src/views/management/ProductCreateView'))
      },
      {
        exact: true,
        path: '/app/management/orders',
        component: lazy(() => import('src/views/management/OrderListView'))
      },
      {
        exact: true,
        path: '/app/management/orders/:orderId',
        component: lazy(() => import('src/views/management/OrderDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/invoices',
        component: lazy(() => import('src/views/management/InvoiceListView'))
      },
      {
        exact: true,
        path: '/app/management/invoices/:invoiceId',
        component: lazy(() => import('src/views/management/InvoiceDetailsView'))
      },
      {
        exact: true,
        path: '/app/content',
        component: () => <Redirect to="/app/content/exams" />
      },
      {
        exact: true,
        path: '/app/calendar',
        component: lazy(() => import('src/views/calendar/CalendarView'))
      },
      {
        exact: true,
        path: '/app/kanban',
        component: lazy(() => import('src/views/kanban/KanbanView'))
      },
      {
        exact: true,
        path: ['/app/chat/new', '/app/chat/:threadKey'],
        component: lazy(() => import('src/views/chat/ChatView'))
      },
      {
        exact: true,
        path: '/app/chat',
        component: () => <Redirect to="/app/chat/new" />
      },
      {
        exact: true,
        path: [
          '/app/mail/label/:customLabel/:mailId?',
          '/app/mail/:systemLabel/:mailId?'
        ],
        component: lazy(() => import('src/views/mail/MailView'))
      },
      {
        exact: true,
        path: '/app/mail',
        component: () => <Redirect to="/app/mail/all" />
      },
      {
        exact: true,
        path: '/app/projects/overview',
        component: lazy(() => import('src/views/projects/OverviewView'))
      },
      {
        exact: true,
        path: '/app/projects/browse',
        component: lazy(() => import('src/views/projects/ProjectBrowseView'))
      },
      {
        exact: true,
        path: '/app/projects/create',
        component: lazy(() => import('src/views/projects/ProjectCreateView'))
      },
      {
        exact: true,
        path: '/app/projects/:id',
        component: lazy(() => import('src/views/projects/ProjectDetailsView'))
      },
      {
        exact: true,
        path: '/app/projects',
        component: () => <Redirect to="/app/projects/browse" />
      },
      {
        exact: true,
        path: '/app/social/feed',
        component: lazy(() => import('src/views/social/FeedView'))
      },
      {
        exact: true,
        path: '/app/social/profile',
        component: lazy(() => import('src/views/social/ProfileView'))
      },
      {
        exact: true,
        path: '/app/social',
        component: () => <Redirect to="/app/social/profile" />
      },
      {
        exact: true,
        path: '/app/extra/charts/apex',
        component: lazy(() => import('src/views/extra/charts/ApexChartsView'))
      },
      {
        exact: true,
        path: '/app/extra/forms/formik',
        component: lazy(() => import('src/views/extra/forms/FormikView'))
      },
      {
        exact: true,
        path: '/app/extra/forms/redux',
        component: lazy(() => import('src/views/extra/forms/ReduxFormView'))
      },
      {
        exact: true,
        path: '/app/extra/editors/draft-js',
        component: lazy(() => import('src/views/extra/editors/DraftEditorView'))
      },
      {
        exact: true,
        path: '/app/extra/editors/quill',
        component: lazy(() => import('src/views/extra/editors/QuillEditorView'))
      },

      {
        exact: true,
        path: '/app/tutoring/curriculums',
        guard: ContentCreatorGuard,
        component: lazy(() => import('src/views/content/CurriculumsView'))
      },
      {
        exact: true,
        path: '/app/tutoring/curriculums/:curriculum',
        guard: ContentCreatorGuard,
        component: lazy(() => import('src/views/content/AgeGroupView'))
      },
      {
        exact: true,
        path: '/app/tutoring/curriculums/:curriculum/:ageGroup',
        guard: ContentCreatorGuard,
        component: lazy(() => import('src/views/content/LevelView'))
      },
      {
        exact: true,
        path: '/app/tutoring/curriculums/:curriculum/:ageGroup/:level',
        guard: ContentCreatorGuard,
        component: lazy(() => import('src/views/content/TopicView'))
      },
      {
        exact: true,
        path: '/app/tutoring/curriculums/:curriculum/:ageGroup/:level/:topic',
        guard: ContentCreatorGuard,
        component: lazy(() => import('src/views/content/WordView'))
      },
      {
        exact: true,
        path: '/app/tutoring/:region/curriculums',
        guard: ContentCreatorGuard,
        component: lazy(() => import('src/views/content/CurriculumsView'))
      },
      {
        exact: true,
        path: '/app/tutoring/:region/:curriculum',
        guard: ContentCreatorGuard,
        component: lazy(() => import('src/views/content/SubjectView'))
      },
      {
        exact: true,
        path: '/app/tutoring/:region/:curriculum/:subject',
        guard: ContentCreatorGuard,
        component: lazy(() => import('src/views/content/TopicView'))
      },
      {
        exact: true,
        guard: ContentCreatorGuard,
        path: '/app/tutoring/:region/:curriculum/:subject/:topic',
        component: lazy(() => import('src/views/content/SectionView'))
      },
      {
        exact: true,
        path: '/app/tutoring/:region/:curriculum/:subject/:topic/:section',
        component: lazy(() => import('src/views/content/LessonView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '/docs',
    layout: DocsLayout,
    routes: [
      {
        exact: true,
        path: '/docs',
        component: () => <Redirect to="/docs/welcome" />
      },
      {
        exact: true,
        path: '/docs/welcome',
        component: lazy(() => import('src/views/docs/WelcomeView'))
      },
      {
        exact: true,
        path: '/docs/getting-started',
        component: lazy(() => import('src/views/docs/GettingStartedView'))
      },
      {
        exact: true,
        path: '/docs/environment-variables',
        component: lazy(() => import('src/views/docs/EnvironmentVariablesView'))
      },
      {
        exact: true,
        path: '/docs/deployment',
        component: lazy(() => import('src/views/docs/DeploymentView'))
      },
      {
        exact: true,
        path: '/docs/api-calls',
        component: lazy(() => import('src/views/docs/ApiCallsView'))
      },
      {
        exact: true,
        path: '/docs/analytics',
        component: lazy(() => import('src/views/docs/AnalyticsView'))
      },
      {
        exact: true,
        path: '/docs/authentication',
        component: lazy(() => import('src/views/docs/AuthenticationView'))
      },
      {
        exact: true,
        path: '/docs/routing',
        component: lazy(() => import('src/views/docs/RoutingView'))
      },
      {
        exact: true,
        path: '/docs/settings',
        component: lazy(() => import('src/views/docs/SettingsView'))
      },
      {
        exact: true,
        path: '/docs/state-management',
        component: lazy(() => import('src/views/docs/StateManagementView'))
      },
      {
        exact: true,
        path: '/docs/theming',
        component: lazy(() => import('src/views/docs/ThemingView'))
      },
      {
        exact: true,
        path: '/docs/support',
        component: lazy(() => import('src/views/docs/SupportView'))
      },
      {
        exact: true,
        path: '/docs/changelog',
        component: lazy(() => import('src/views/docs/ChangelogView'))
      },

      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/home',
        component: HomeView
      },
      {
        exact: true,
        path: '/pricing',
        component: lazy(() => import('src/views/pages/PricingView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

const renderRoutes = routes =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={props => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
