import React from 'react';

import { Chip } from '@material-ui/core';

import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import {
  Briefcase as BriefcaseIcon,
  Calendar as CalendarIcon,
  ShoppingCart as ShoppingCartIcon,
  Folder as FolderIcon,
  BarChart as BarChartIcon,
  UserPlus as UserPlusIcon,
  Shield as ShieldIcon,
  Sliders as SlidersIcon,
  AlertCircle as AlertCircleIcon,
  Trello as TrelloIcon,
  User as UserIcon,
  Layout as LayoutIcon,
  Edit as EditIcon,
  Airplay as AirplayIcon,
  DollarSign as DollarSignIcon,
  Mail as MailIcon,
  MessageCircle as MessageCircleIcon,
  PieChart as PieChartIcon,
  Share2 as ShareIcon,
  BookOpen as BookOpenIcon,
  Lock as LockIcon,
  Users as UsersIcon,
  UserCheck as UserCheckIcon,
  Smile as SmileIcon,
  TrendingUp as TrendingUpIcon,
  Award as AwardIcon,
  List as ListIcon
} from 'react-feather';
import { FaFileInvoice } from 'react-icons/fa';

const superAdminNav = [
  {
    subheader: 'Overview',
    items: [
      {
        title: 'Dashboard Standard',
        icon: PieChartIcon,
        href: '/app/reports/dashboard-standard'
      },
      {
        title: 'Dashboard Alternative',
        icon: BarChartIcon,
        href: '/app/reports/dashboard-alternative'
      },
      {
        title: 'Dashboard',
        icon: PieChartIcon,
        href: '/app/reports/dashboard'
      }
    ]
  },
  {
    subheader: 'Tutoring',
    items: [
      {
        title: 'Customers',
        icon: SmileIcon,
        href: '/app/tutoring/customers',
        items: [
          {
            title: 'List Customers',
            href: '/app/tutoring/customers'
          },
          {
            title: 'List Dependents',
            href: '/app/tutoring/dependents'
          },
          {
            title: 'List Customer Pods',
            href: '/app/tutoring/customer-pods'
          }
        ]
      },
      {
        title: 'Tutors',
        icon: AwardIcon,
        href: '/app/tutoring/tutors',
        items: [
          {
            title: 'List Tutors',
            href: '/app/tutoring/tutors'
          },
          {
            title: 'Calendar',
            href: '/app/tutoring/calendar'
          },
          {
            title: 'Hiring',
            href: '/app/tutoring/onboarding'
          },
          {
            title: 'Rejected',
            href: '/app/tutoring/rejected-tutors'
          }
        ]
      },
      {
        title: 'Lessons',
        icon: BookOpenIcon,
        href: '/app/content',
        items: [
          {
            title: 'Lesson Requests',
            href: '/app/tutoring/admin-lesson-requests'
          },
          {
            title: 'Confirmed Lessons',
            href: '/app/tutoring/admin-confirmed-lessons'
          },
          {
            title: 'Booked Lessons',
            href: '/app/tutoring/admin-booked-lessons'
          },
          {
            title: 'Learning Pods',
            href: '/app/tutoring/group-lessons'
          },
          {
            title: 'Virtual Immersions',
            href: '/app/tutoring/virtual-immersion'
          },
          {
            title: 'Tutor Feedback',
            href: '/app/tutoring/feedbacks'
          }
        ]
      },
      {
        title: 'Referral Scheme',
        icon: UserPlusIcon,
        href: '/app/content',
        items: [
          {
            title: 'Teacher Referrers',
            href: '/app/tutoring/teacher-referrers'
          },
          {
            title: 'Customer Referrers',
            href: '/app/tutoring/customer-referrers'
          }
        ]
      },
      {
        title: 'Curriculums',
        icon: BookOpenIcon,
        href: '/app/tutoring',
        items: [
          {
            title: 'Language Curriculum',
            href: '/app/tutoring/curriculums'
          },
          {
            title: 'Curriculum',
            href: '/app/tutoring/regions'
          }
        ]
      },
      {
        title: 'Invoice',
        icon: FaFileInvoice,
        href: '/app/tutoring',
        items: [
          {
            title: 'PrePayment Invoice',
            href: '/app/tutoring/pre-payment-invoice'
          },
          {
            title: 'Ongoing Invoice',
            href: '/app/tutoring/ongoing-customer-invoice'
          },
          {
            title: 'Promos',
            href: '/app/tutoring/invoice/promos'
          },
          {
            title: 'Create Invoice',
            href: '/app/tutoring/invoice/new-pre-payment-invoice'
          }
        ]
      },
      {
        title: 'Reports',
        icon: TrendingUpIcon,
        href: '/app/content',
        items: [
          {
            title: 'Booked Lessons',
            href: '/app/tutoring/reports/student-lessons'
          },
          {
            title: 'Rescheduled Lessons',
            href: '/app/tutoring/reports/rescheduled-lessons'
          },
          {
            title: 'Virtual Immersions',
            href: '/app/tutoring/reports/virtual-immersions'
          },

          {
            title: 'Student Retention',
            href: '/app/tutoring/reports/student-retention'
          },
          {
            title: 'Lesson Ratings',
            href: '/app/tutoring/reports/student-rating'
          },
          {
            title: 'Direct to Pay Leads',
            href: '/app/tutoring/reports/customer-leads'
          }
        ]
      },
      {
        title: 'Activity Logs',
        icon: ListIcon,
        href: '/app/content',
        items: [
          {
            title: 'Team Activities',
            href: '/app/reports/team-activities'
          }
        ]
      }
      // {
      //   title: 'Group Lessons',
      //   icon: BookOpenIcon,
      //   href: '/app/content',
      //   items: [
      //     {
      //       title: 'View Lessons',
      //       href: '/app/tutoring/group-lessons'
      //     },
      //     {
      //       title: 'Create',
      //       href: '/app/tutoring/group-lessons/create'
      //     }
      //   ]
      // }
    ]
  },
  {
    subheader: 'Language App',
    items: [
      {
        title: 'Words List',
        icon: BookOpenIcon,
        href: '/app/words-list',
        items: [
          {
            title: 'Manage Words',
            href: '/app/langauge-app/words-list'
          },
          {
            title: 'Add Word',
            href: '/app/langauge-app/create-word'
          }
        ]
      },
      {
        title: 'Sentence Construction',
        icon: SlidersIcon,
        href: '/app/sentence-construction',
        items: [
          {
            title: 'Manage Sessions',
            href: '/app/langauge-app/sentence-construction'
          },
          {
            title: 'Add Sessions',
            href: '/app/langauge-app/create-sentence-construction-session'
          }
        ]
      }
    ]
  },
  {
    subheader: 'Learning',
    items: [
      {
        title: 'App Content',
        icon: BookOpenIcon,
        href: '/app/content',
        items: [
          {
            title: 'Curriculum',
            href: '/app/content/exams'
          },
          {
            title: 'Lessons Content Status',
            href: '/app/content/lesson-status'
          },
          {
            title: 'Content Creators',
            href: '/app/content/content-creators'
          }
        ]
      },

      {
        title: 'Customers',
        icon: SmileIcon,
        href: '/app/management/customers',
        items: [
          {
            title: 'List Customers',
            href: '/app/management/customers'
          },
          {
            title: 'View Customer',
            href: '/app/management/customers/1'
          },
          {
            title: 'Edit Customer',
            href: '/app/management/customers/1/edit'
          }
        ]
      }
    ]
  },
  {
    subheader: 'Management',
    items: [
      {
        title: 'Dashboard Users',
        icon: UserCheckIcon,
        href: '/app/management/admins',
        items: [
          {
            title: 'List Dashboard Users',
            href: '/app/management/users'
          },
          {
            title: 'Create Dashboard User',
            href: '/app/management/create/user'
          }
        ]
      },
      {
        title: 'Products',
        icon: ShoppingCartIcon,
        href: '/app/management/products',
        items: [
          {
            title: 'List Products',
            href: '/app/management/products'
          },
          {
            title: 'Create Product',
            href: '/app/management/products/create'
          }
        ]
      },
      {
        title: 'Orders',
        icon: FolderIcon,
        href: '/app/management/orders',
        items: [
          {
            title: 'List Orders',
            href: '/app/management/orders'
          },
          {
            title: 'View Order',
            href: '/app/management/orders/1'
          }
        ]
      },
      {
        title: 'Invoices',
        icon: ReceiptIcon,
        href: '/app/management/invoices',
        items: [
          {
            title: 'List Invoices',
            href: '/app/management/invoices'
          },
          {
            title: 'View Invoice',
            href: '/app/management/invoices/1'
          }
        ]
      }
    ]
  },
  {
    subheader: 'Applications',
    items: [
      {
        title: 'Projects Platform',
        href: '/app/projects',
        icon: BriefcaseIcon,
        items: [
          {
            title: 'Overview',
            href: '/app/projects/overview'
          },
          {
            title: 'Browse Projects',
            href: '/app/projects/browse'
          },
          {
            title: 'Create Project',
            href: '/app/projects/create'
          },
          {
            title: 'View Project',
            href: '/app/projects/1'
          }
        ]
      },
      {
        title: 'Social Platform',
        href: '/app/social',
        icon: ShareIcon,
        items: [
          {
            title: 'Profile',
            href: '/app/social/profile'
          },
          {
            title: 'Feed',
            href: '/app/social/feed'
          }
        ]
      },
      {
        title: 'Kanban',
        href: '/app/kanban',
        icon: TrelloIcon
      },
      {
        title: 'Mail',
        href: '/app/mail',
        icon: MailIcon,
        info: () => <Chip color="secondary" size="small" label="Updated" />
      },
      {
        title: 'Chat',
        href: '/app/chat',
        icon: MessageCircleIcon,
        info: () => <Chip color="secondary" size="small" label="Updated" />
      },
      {
        title: 'Calendar',
        href: '/app/calendar',
        icon: CalendarIcon
      }
    ]
  },
  {
    subheader: 'Auth',
    items: [
      {
        title: 'Login',
        href: '/login-unprotected',
        icon: LockIcon
      },
      {
        title: 'Register',
        href: '/register-unprotected',
        icon: UserPlusIcon
      },
      {
        title: 'Login: Guest Protected',
        href: '/login',
        icon: ShieldIcon
      },
      {
        title: 'Register: Guest Protected',
        href: '/register',
        icon: ShieldIcon
      }
    ]
  },
  {
    subheader: 'Pages',
    href: '/app/pages',
    items: [
      {
        title: 'Account',
        href: '/app/account',
        icon: UserIcon
      },
      {
        title: 'Error',
        href: '/404',
        icon: AlertCircleIcon
      },
      {
        title: 'Pricing',
        href: '/pricing',
        icon: DollarSignIcon
      }
    ]
  },
  {
    subheader: 'Extra',
    items: [
      {
        title: 'Charts',
        href: '/app/extra/charts',
        icon: BarChartIcon,
        items: [
          {
            title: 'Apex Charts',
            href: '/app/extra/charts/apex'
          }
        ]
      },
      {
        title: 'Forms',
        href: '/app/extra/forms',
        icon: EditIcon,
        items: [
          {
            title: 'Formik',
            href: '/app/extra/forms/formik'
          },
          {
            title: 'Redux Forms',
            href: '/app/extra/forms/redux'
          }
        ]
      },
      {
        title: 'Editors',
        href: '/app/extra/editors',
        icon: LayoutIcon,
        items: [
          {
            title: 'DraftJS Editor',
            href: '/app/extra/editors/draft-js'
          },
          {
            title: 'Quill Editor',
            href: '/app/extra/editors/quill'
          }
        ]
      }
    ]
  }
];

export default superAdminNav;
