import {
  TrendingUp as TrendingUpIcon,
  FileText as FileTextIcon,
  Airplay as AirplayIcon,
  BookOpen as BookOpenIcon,
  Users as UsersIcon,
  UserCheck as UserCheckIcon,
  Sliders as SlidersIcon
} from 'react-feather';

const superAdminNav = [
  {
    subheader: 'Learning',
    items: [
      {
        title: 'Words List',
        icon: BookOpenIcon,
        href: '/app/words-list',
        items: [
          {
            title: 'Manage Words',
            href: '/app/langauge-app/words-list'
          },
          {
            title: 'Add Word',
            href: '/app/langauge-app/create-word'
          }
        ]
      },
      {
        title: 'Sentence Construction',
        icon: SlidersIcon,
        href: '/app/sentence-construction',
        items: [
          {
            title: 'Manage Sessions',
            href: '/app/langauge-app/sentence-construction'
          },
          {
            title: 'Add Sessions',
            href: '/app/langauge-app/create-sentence-construction-session'
          }
        ]
      }
    ]
  }
];

export default superAdminNav;
