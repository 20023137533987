export const SET_PRE_PAYMENT_INVOICE = '@lessonRequest/set-pre-payment-invoice';
export const SET_PRE_PAYMENT_INVOICES =
  '@lessonRequest/set-pre-payment-invoices';
export const EDIT_PRE_PAYMENT_INVOICE =
  '@lessonRequest/edit-pre-payment-invoice';
  export const  CLEAR_PRE_PAYMENT_INVOICE =
  '@lessonRequest/clear-pre-payment-invoice';

export function setPrePaymentInvoice(values) {
  return dispatch => {
    dispatch({
      type: SET_PRE_PAYMENT_INVOICE,
      payload: values
    });
  };
}
export function clearPrePaymentInvoice(values) {
  return dispatch => {
    dispatch({
      type: CLEAR_PRE_PAYMENT_INVOICE,
      payload: values
    });
  };
}
export function setPrePaymentInvoices(values) {
  return dispatch => {
    dispatch({
      type: SET_PRE_PAYMENT_INVOICES,
      payload: values
    });
  };
}
export function editPrePaymentInvoice(values) {
  return dispatch => {
    dispatch({
      type: EDIT_PRE_PAYMENT_INVOICE,
      payload: values
    });
  };
}
