/* eslint-disable no-param-reassign */
import {
  EDIT_PRE_PAYMENT_INVOICE,
  SET_PRE_PAYMENT_INVOICE,
  SET_PRE_PAYMENT_INVOICES,
  CLEAR_PRE_PAYMENT_INVOICE 
} from 'src/actions/invoiceActions';
import { v4 as uuidv4 } from 'uuid';

const initialState = {
  prePaymentInvoices: [],
  prePaymentInvoice: {
    firstName: '',
    lastName: '',
    email: '',
    gender: '',
    phoneNumber: '',
    secondary_email: '',
    zohoEmail: '',
    timeZone: '',
    subjects: [],
    tutors: [],
    lessonPrice: {},
    whoIsLessonFor: 'me',
    type: 'FIXED',
    paymentGateway: ''
  }
};

const invoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRE_PAYMENT_INVOICE: {
      const { field, value, invoice } = action.payload;
      if (field?.includes('.')) {
        const [key, val] = field.split('.');
        if (state[key]) {
          state[key] = val;
        }
      }
      return {
        ...state,
        prePaymentInvoice:
          field === '_id'
            ? {
                whoIsLessonFor: 'me'
              }
            : invoice
            ? {
                ...initialState.prePaymentInvoice,
                ...invoice
              }
            : {
                ...state.prePaymentInvoice,
                [field?.includes('.')
                  ? field.split('.')[0]
                  : field]: field?.includes('.')
                  ? {
                      ...state.prePaymentInvoice[field.split('.')[0]],
                      [field.split('.')[1]]: value
                    }
                  : value
              }
      };
    }

    case SET_PRE_PAYMENT_INVOICES: {
      const value = action.payload;

      return {
        ...state,
        prePaymentInvoices: Array.isArray(value)
          ? value
          : [...state.prePaymentInvoices, value]
      };
    }

    case EDIT_PRE_PAYMENT_INVOICE:
      const value = action.payload;
      return {
        ...state,
        prePaymentInvoices: state.prePaymentInvoices.map(invoice =>
          invoice._id === value._id ? value : invoice
        )
      };
  // New case to handle clearing the prePaymentInvoice
  case CLEAR_PRE_PAYMENT_INVOICE: {
    return {
      ...state,
      prePaymentInvoice: initialState.prePaymentInvoice // Reset to initial state
    };
  }
    default: {
      return state;
    }
  }
};

export default invoiceReducer;
